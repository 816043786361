<template>
  <div id="loading-bg">
    <div class="loading-logo">
      <img src="@/assets/images/logo/logo.svg" alt="Logo">
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    handleLoginEvent(data) {
      this.$store.dispatch('updateUserInfo', {
        displayName: data.fullName,
        email: data.email,
        photoURL: data.profileImage,
        providerId: data.profile.sub.substr(0, data.profile.sub.indexOf('|')),
        uid: data._id,
        status:'online',
        userRole: data.userType
      })
      console.log({data})

      this.$acl.change(data.userType);
      this.$router.push(data.state.target || "/");
    }
  },
  created() {
    console.log('auth')
    this.$auth.handleAuthentication()
    if (this.$auth.isAuthenticated()) this.$router.push('/').catch(() => {})
  }
}

</script>

<style lang="scss">
@import "../assets/css/loader.css";
</style>
